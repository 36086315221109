.media_lists {
    display              : grid;
    grid-template-columns: repeat(6, 1fr);
    gap                  : 2.6rem;
}

.media_lists .add_media {
    position: relative;
}
.media_lists .add_media input {
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.media_lists .list_card {
    display: inline-block;
    text-align: center;
    border       : 1px solid var(--gray-4);
    border-radius: 1rem;
    padding      : .6rem 1rem 1.5rem;
}

.media_lists .icon {
    min-width: 12.3rem;
    height: 12.2rem;
    color        : var(--gray-4);
    margin-bottom: 1.8rem;
    overflow: hidden;
}

.media_lists .icon img {
    width: 100%;
    height: 12.2rem;
    object-fit: cover;
    object-position: center;
}

.copy_clipboard {
    display        : flex;
    justify-content: space-between;
}

.media_lists span {
    font-weight: 400;
    font-size  : 12px;
    line-height: 15px;
    color      : var(--gray-1);
}

.copy_clipboard button {
    color: var(--gray-1);
}


.media_lists .icon svg {
    display: inline-block;
    width  : 12.3rem;
    height : 12.2rem;
}
