#dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav_card p{
    display: block;
}
.pending_request{
    font-size: 5rem;
    color: rgb(121, 126, 131);
}
.nav_card:hover .pending_request{
 color: #f9bd34;
}