.create_btn_box {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    margin-bottom: 4.2rem;
}

.btn_create_collection {
    display      : flex;
    align-items  : center;
    color        : var(--gray-4);
    text-transform: capitalize;
    border-radius: .5rem;
    border       : 1px solid var(--gray-4);
    padding      : 1.4rem 2.4rem;
}

.btn_create_collection.fill_yellow {
    color        : var(--primary-color);
    border       : 1px solid var(--primary-color);
}


.btn_create_collection svg {
    display: inline-block;
    width  : 2rem;
    height : 2rem;
}

.btn_create_collection span {
    font-weight: 600;
    font-size  : 1.5rem;
    line-height: 1.9rem;
    margin-left: .8rem;
}

.folder_collection {
    display: flex;
}

.folder_collection .folder {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    color         : var(--primary-color);
}

.folder_collection .folder svg {
    display: inline-block;
    width  : 8.2rem;
    height : 6.6rem;
}

.folder_collection .folder span {
    font-weight: 600;
    font-size  : 1.6rem;
    line-height: 2.0rem;
    color      : #E6E6E6;
    margin-top : 1rem;
}

.media_label p {
    margin-bottom: 2rem;
}